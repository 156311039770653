import {
  Box,
  Stack,
  Typography,
  Link,
  Divider,
} from "@mui/material";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import XIcon from '@mui/icons-material/X';
import { newIndustries} from "../utils/data";
import { useRouter } from "next/router";
import CusAccordion from "./CusAccordion";
import GradientDivider from "./GradientDivider";
function Footer() {

  const services = [

    {
      id: '/services/Software-Development',
      title: "Software Development",
    },
    {
      id: '/services/Hardware-Engineering-Service',
      title: "Hardware Engineering",
      },
    {
      id: '/services/Design-Service',
      title: "Product Design",
      },

    {
      id: '/services/Research-Development-Service',
      title: " Research & Development",
    },
  ];


  const products = [
    "SaaS",
    "ERP",
    "CMS",
    "E-Commerce",
    "CRM",
    "Delivery solution",
    "Inventory management solution",
    "Energy consumption system",
    "Production monitoring system",
  ];
  const overview = [
    {
      path: "/",
      name: "Home",
    },
    {
      path: "/about",
      name: "About",
    },
    {
      path: "/services",
      name: "Services",
    },
    {
      path: "/industries",
      name: "Industries",
    },
    {
      path: "/careers",
      name: "Careers",
    },
    {
      path: "/contact-us",
      name: "Contact Us",
    },
    {
      path: "/privacy-policies",
      name: "Privacy",
    },
    {
      path: "/terms-and-conditions",
      name: "Terms & Conditions",
    },
  ];
  const router = useRouter();

  return (
    <>
      <Box
        sx={{
          width: "100%",
          zIndex: 25,
          display: "flex",
          flexDirection: "column",
          gap: ["1rem", "1.2rem", "1.8rem", "2rem", "3rem"],
          py: ["1rem", "1.2rem", "1.8rem", "2rem"],
          pt: [3, 3, 7, 6],
          px: ["0", "2.2rem", "0"],
          color: "#fff",
        }}
      >
        <Stack
          sx={{
            columnGap: ["2rem", "2rem", "2rem", "2rem", "6rem"],
            gap: "0rem",
            
          }}
          direction={{ xs: "column", sm: "row" }}
          gap={{ xs: 2, sm: 3, md: 1, }}
          flexWrap={{ sm: "wrap" }}
          justifyContent={{
            xs: "space-between",
            sm: "center",
            md: "center",
            lg: "space-around",
          }}
        >
          {/* Address */}

          <CusAccordion
            display={["flex", "flex", "none"]}
            head={
              <Typography
                sx={{
                  fontSize: ["1rem"],
                  fontWeight: 600,
                  color: "#7624F3",
                  background: "linear-gradient(91.83deg, #FF512F 0%, #DD2476 100%)",
                  backgroundClip: "text",
                  textFillColor: "transparent",
                  letterSpacing: 3,
                  textTransform: "uppercase",
                }}
              >
                Address
              </Typography>
            }
            body={
              <Stack
                direction="column"
                width={{ xs: "100%", sm: "auto" }}
                gap={{ xs: 3, sm: 
                  3 }}
              >
                <Stack
                  direction="column"
                  width={{ xs: "100%", sm: "auto" }}
                  gap={{ xs: 0, sm: 0.7 }}
                >
                  <Typography
                    sx={{
                      fontSize: [".85rem", ".85rem", "12px", "12px", ".85rem"],
                      fontWeight: 600,
                      letterSpacing: 3,
                      color: "#E8335E",
                      textTransform: "uppercase",
                    }}
                  >
                    India
                  </Typography>
                  <Stack
                    direction="column"
                    width={{ xs: "100%", sm: "auto" }}
                    gap={{ xs: 1, sm: 1 }}
                  >
                    <Typography
                      sx={{
                        fontSize: [
                          ".85rem",
                          ".85rem",
                          "12px",
                          "12px",
                          ".85rem",
                        ],
                      }}
                    >
                      Thanjavur <br />
                      6/12 Papa vaikal Street Ayyampettai <br />
                      Papanasam Thanjavur
                      <br />
                      Tamilnadu India.
                    </Typography>
                  </Stack>
                </Stack>
                <Stack
                  direction="column"
                  width={{ xs: "100%", sm: "auto" }}
                  gap={{ xs: 0, sm: 0.7 }}
                >
                  <Typography
                    sx={{
                      fontSize: [".85rem", ".85rem", "12px", "12px", ".85rem"],
                      fontWeight: 600,
                      letterSpacing: 3,
                      color: "#E8335E",
                      textTransform: "uppercase",
                    }}
                  >
                    United Kingdom
                  </Typography>
                  <Stack
                    direction="column"
                    width={{ xs: "100%", sm: "auto" }}
                    gap={{ xs: 1, sm: 1 }}
                  >
                    <Typography
                      sx={{
                        fontSize: [
                          ".85rem",
                          ".85rem",
                          "12px",
                          "12px",
                          ".85rem",
                        ],
                      }}
                    >
                      London
                      <br />
                      71-75 Shelton Street, Covent Garden,
                      <br />
                      London, WC2H 9JQ, United Kingdom.
                    </Typography>
                  </Stack>
                </Stack>

                <Stack
                  direction="column"
                  width={{ xs: "100%", sm: "auto" }}
                  gap={{ xs: 0, sm: 0.7 }}
                >
                  <Typography
                    sx={{
                      fontSize: [".85rem", ".85rem", "12px", "12px", ".85rem"],
                      fontWeight: 600,
                      color: "#7624F3",
                      background: "linear-gradient(91.83deg, #FF512F 0%, #DD2476 100%)",
                      backgroundClip: "text",
                      textFillColor: "transparent",
                      letterSpacing: 3,
                      textTransform: "uppercase",
                    }}
                  >
                    Contact us
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: [".85rem", ".85rem", "12px", "12px", ".85rem"],
                      ":hover": {
                        color: "#F74641",
                        textDecoration:'none',
                      },
                    }}
                  >
                    <Link
                      color={"inherit"}
                      underline="hover"
                      href="mailto:contact@aattralsupremacy.com"
                    >
                      contact@techbrainnetworks.com
                    </Link>
                  </Typography>
                </Stack>
              </Stack>
            }
          />

          <Stack
            direction="column"
            width={{ xs: "100%", sm: "auto" }}
            gap={{ xs: 0.5, sm: 1 }}
            sx={{
              display: ["none", "none", "flex"],
            }}
          >
            <Typography
              sx={{
                fontSize: ["1.5rem", ".85rem", "20px", "25px", "2rem"],
                fontWeight: 600,
                color: "#7624F3",
                background: "linear-gradient(91.83deg, #FF512F 0%, #DD2476 100%)",
                backgroundClip: "text",
                textFillColor: "transparent",
                letterSpacing: 2,
                textTransform: "uppercase",
              }}
            >
              Address
            </Typography>
            <Stack
              direction="column"
              width={{ xs: "100%", sm: "auto" }}
              gap={{ xs: 0, sm: 0.7 }}
            >
              <Typography
                sx={{
                  fontSize: ["1.5rem", ".85rem", "12px", "15px", "1.5rem"],
                  fontWeight: 600,
                  letterSpacing: 2,
                  color: "#E8335E",
                  textTransform: "uppercase",
                }}
              >
                India
              </Typography>
              <Stack
                direction="column"
                width={{ xs: "100%", sm: "auto" }}
                gap={{ xs: 1, sm: 1 }}
              >
                <Typography
                  sx={{
                    fontSize: ["1.2rem", ".85rem", "12px", "15px", "1.2rem"],
                  }}
                >
                  Thanjavur <br />
                  6/12 Papa vaikal Street Ayyampettai <br />
                  Papanasam Thanjavur
                  <br />
                  Tamilnadu India.
                </Typography>
              </Stack>
            </Stack>
            <Stack
              direction="column"
              width={{ xs: "100%", sm: "auto" }}
              gap={{ xs: 0, sm: 0.7 }}
            >
              <Typography
                sx={{
                  fontSize: ["1.5rem", ".85rem", "12px", "15px", "1.5rem"],
                  fontWeight: 600,
                  letterSpacing: 2,
                  color: "#E8335E",
                  textTransform: "uppercase",
                }}
              >
                United Kingdom
              </Typography>
              <Stack
                direction="column"
                width={{ xs: "100%", sm: "auto" }}
                gap={{ xs: 1, sm: 1 }}
              >
                <Typography
                  sx={{
                    fontSize: ["1.2rem", ".85rem", "12px", "15px", "1.2rem"],
                    color: "#f6f6f6",
                  }}
                >
                  London
                  <br />
                  71-75 Shelton Street, Covent Garden,
                  <br />
                  London, WC2H 9JQ, United Kingdom.
                </Typography>
              </Stack>
            </Stack>

            <Stack
              direction="column"
              width={{ xs: "100%", sm: "auto" }}
              gap={{ xs: 0, sm: 0.7 }}
            >
              <Typography
                sx={{
                  fontSize: ["1.5rem", ".85rem", "20px", "25px", "2rem"],
                  fontWeight: 600,
                  color: "#7624F3",
                  background: "linear-gradient(91.83deg, #FF512F 0%, #DD2476 100%)",
                  backgroundClip: "text",
                  textFillColor: "transparent",
                  letterSpacing: 2,
                  textTransform: "uppercase",
                }}
              >
                Contact us
              </Typography>
              <Typography
                sx={{
                  fontSize: ["1.2rem", ".85rem", "12px", "15px", "1.2rem"],
                  color: "#f6f6f6",
                  ":hover": {
                    color: "#F74641",
                    textDecoration:'none',
                  },
                }}
              >
                <Link
                  color={"inherit"}
                  underline="hover"
                  href="mailto:contact@aattralsupremacy.com"
                >
                  contact@techbrainnetworks.com
                </Link>
              </Typography>
            </Stack>
          </Stack>

          {/* Services */}

          <CusAccordion
            display={["flex", "flex", "none"]}
            head={
              <Typography
                sx={{
                  fontSize: ["1rem"],
                  fontWeight: 600,
                  color: "#7624F3",
                  background: "linear-gradient(91.83deg, #FF512F 0%, #DD2476 100%)",
                  backgroundClip: "text",
                  textFillColor: "transparent",
                  letterSpacing: 3,
                  textTransform: "uppercase",
                }}
              >
                Services
              </Typography>
            }
            body={
              <Stack direction={{ xs: "column" }} gap={{ xs: 1.2, sm: 2 }}>
                {services.slice(0, 10).map((item, index) => (
                  <Typography
                    key={index}
                    component={Link}
                    href={`${item.id}`}
                    color={"inherit"}
                    underline="hover"
                    sx={{
                      fontSize: [".85rem", ".85rem", "12px", "12px", ".85rem"],
                      ":hover": {
                        color: "#F74641",
                        textDecoration:'none'
                      },
                    }}
                  >
                    {item.title}
                  </Typography>
                ))}
              </Stack>
            }
          />


<Box
            display="flex"
            flexDirection={{ xs: "column" }}
            alignItems={{ xs: "flex-start" }}
            gap={{ xs: 0.5, sm: 1 }}
            color="#fff"
            sx={{
              display: ["none", "none", "flex"],
            }}
          >
            <Typography
              sx={{
                fontSize: ["1.5rem", ".85rem", "20px", "25px", "2rem"],
                fontWeight: 600,
                color: "#7624F3",
                background: "linear-gradient(91.83deg, #FF512F 0%, #DD2476 100%)",
                backgroundClip: "text",
                textFillColor: "transparent",
                letterSpacing: 2,
                textTransform: "uppercase",
              }}
            >
              Industries
            </Typography>
            <Stack direction={{ xs: "row" }} gap={{ xs: 1.2, sm: 10 }}>
              <Stack direction={{ xs: "column" }} gap={{ xs: 1.2, sm: 2 }}>
                {newIndustries.slice(0, 12).map((item, index) => (
                  <Typography
                    key={index}
                    component={Link}
                    href={item.id}
                    color={"inherit"}
                    underline="hover"
                    sx={{
                      fontSize: ["1.2rem", ".85rem", "12px", "15px", "1.2rem"],
                      ":hover": {
                        color: "#F74641 ",
                        textDecoration:'none'
                      },
                    }}
                  >
                    {item.title}
                  </Typography>
                ))}
              </Stack>

              <Stack direction={{ xs: "column" }} gap={{ xs: 1.2, sm: 2 }}>
                {newIndustries.slice(12, 25).map((item, index) => (
                  <Typography
                    key={index}
                    component={Link}
                    href={item.id}
                    color={"inherit"}
                    underline="hover"
                    sx={{
                      fontSize: ["1.2rem", ".85rem", "12px", "15px", "1.2rem"],
                      ":hover": {
                        color: "#F74641 ",
                        textDecoration:'none'
                      },
                    }}
                  >
                    {item.title}
                  </Typography>
                ))}
              </Stack>
            </Stack>
          </Box>

          <Box
            display="flex"
            flexDirection={{ xs: "column" }}
            alignItems={{ xs: "flex-start" }}
            gap={{ xs: 0.5, sm: 1 }}
            color="#fff"
            sx={{
              display: ["none", "none", "flex"],
            }}
          >
            <Typography
              sx={{
                fontSize: ["1.5rem", ".85rem", "20px", "25px", "2rem"],
                fontWeight: 600,
                color: "#7624F3",
                background: "linear-gradient(91.83deg, #FF512F 0%, #DD2476 100%)",
                backgroundClip: "text",
                textFillColor: "transparent",
                letterSpacing: 2,
                textTransform: "uppercase",
              }}
            >
              Services
            </Typography>
            <Stack direction={{ xs: "column" }} gap={{ xs: 1.2, sm: 2 }}>
              {services.slice(0, 10).map((item, index) => (
                <Typography
                  key={index}
                  component={Link}
                  href={`${item.id}`}
                  color={"inherit"}
                  underline="hover"
                  sx={{
                    fontSize: ["1.2rem", ".85rem", "12px", "15px", "1.2rem"],
                    ":hover": {
                      color: "#F74641 ",
                      textDecoration:'none'
                    },
                  }}
                >
                  {item.title}
                </Typography>
              ))}
            </Stack>
          </Box>

          <CusAccordion
            display={["flex", "flex", "none"]}
            head={
              <Typography
                sx={{
                  fontSize: ["1rem"],
                  fontWeight: 600,
                  color: "#7624F3",
                  background: "linear-gradient(91.83deg, #FF512F 0%, #DD2476 100%)",
                  backgroundClip: "text",
                  textFillColor: "transparent",
                  letterSpacing: 3,
                  textTransform: "uppercase",
                }}
              >
                Industries
              </Typography>
            }
            body={
              <Stack direction={{ xs: "row" }} gap={{ xs: 1.2, sm: 4 }}>
                <Stack direction={{ xs: "column" }} gap={{ xs: 1.2, sm: 2 }}>
                  {newIndustries.slice(0, 12).map((item, index) => (
                    <Typography
                      key={index}
                      component={Link}
                      href={item.id}
                      color={"inherit"}
                      underline="hover"
                      sx={{
                        fontSize: [
                          ".85rem",
                          ".85rem",
                          "12px",
                          "12px",
                          ".85rem",
                        ],
                        ":hover": {
                          color: "#F74641",
                          textDecoration:'none'
                        },
                      }}
                    >
                      {item.title}
                    </Typography>
                  ))}
                </Stack>

                <Stack direction={{ xs: "column" }} gap={{ xs: 1.2, sm: 2 }}>
                  {newIndustries.slice(12, 25).map((item, index) => (
                    <Typography
                      key={index}
                      component={Link}
                      href={item.id}
                      color={"inherit"}
                      underline="hover"
                      sx={{
                        fontSize: [
                          ".85rem",
                          ".85rem",
                          "12px",
                          "12px",
                          ".85rem",
                        ],
                        ":hover": {
                          color: "#F74641",
                          textDecoration:'none'
                        },
                      }}
                    >
                      {item.title}
                    </Typography>
                  ))}
                </Stack>
              </Stack>
            }
          />

        

          <CusAccordion
            display={["flex", "flex", "none"]}
            lastChild
            head={
              <Typography
                sx={{
                  fontSize: ["1rem"],
                  fontWeight: 600,
                  color: "#7624F3",
                  background: "linear-gradient(91.83deg, #FF512F 0%, #DD2476 100%)",
                backgroundClip: "text",
                textFillColor: "transparent",
                  letterSpacing: 3,
                  textTransform: "uppercase",
                }}
              >
                Overview
              </Typography>
            }
            body={
              <Stack direction={{ xs: "column" }} gap={{ xs: 1.2, sm: 2 }}>
                {overview.slice(0, 10).map((item, index) => (
                  <Typography
                    key={index}
                    component={Link}
                    href={item.path}
                    color={"inherit"}
                    underline="hover"
                    sx={{
                      fontSize: [".85rem", ".85rem", "12px", "12px", ".85rem"],
                      ":hover": {
                        color: "#F74641",
                        textDecoration:'none'
                      },
                    }}
                  >
                    {item.name}
                  </Typography>
                ))}
              </Stack>
            }
          />
        </Stack>
        <GradientDivider/>

        <Stack
          direction={"column"}
          justifyContent={"center"}
          alignItems={"center"}
          sx={{
            width: "100%",
            gap: 3,
          }}
        >
          <Typography
                sx={{
                  fontSize: ["1.2rem", "1.2rem", "20px", "25px", "2.5rem"],
                  fontWeight: 600,
                  color: "#7624F3",
                  background: "linear-gradient(91.83deg, #FF512F 0%, #DD2476 100%)",
                  backgroundClip: "text",
                  textFillColor: "transparent",
                  letterSpacing: 3,
                  textTransform: "uppercase",
                }}
              >
                TECHBRAIN NETWORKS
              </Typography>
          <Stack direction={{ xs: "row" }} gap={{ xs: 1, md: 2 }}>
            <Link href="https://www.linkedin.com/company/techbrain-networks/" color="inherit" underline="none">
              <LinkedInIcon
                sx={{
                  fontSize: ["1.3", "1.6rem", "1.7rem", "1.8rem", "2.5rem"],
                  ":hover": {
                    color: "#F74641",
                  },
                }}
              />
            </Link>
            <Link href="" color="inherit" underline="none">
              <FacebookIcon
                sx={{
                  fontSize: ["1.3", "1.6rem", "1.7rem", "1.8rem", "2.5rem"],
                  ":hover": {
                    color: "#F74641",
                  },
                }}
              />
            </Link>
            <Link href="" color="inherit" underline="none">
              <XIcon
                sx={{
                  fontSize: ["1.3rem", "1.5rem", "1.6rem", "1.7rem", "2.1rem"],
                  ":hover": {
                    color: "#F74641",
                  },
                }}
              />
            </Link>
            <Link href="" color="inherit" underline="none">
              <InstagramIcon
                sx={{
                  fontSize: ["1.4rem", "1.6rem", "1.7rem", "1.8rem", "2rem"],
                  ":hover": {
                    color: "#F74641",
                  },
                }}
              />
            </Link>
          </Stack>
          {/* overview */}
          <Stack
            direction={"row"}
            justifyContent={"center"}
            sx={{ 
              flexWrap: "wrap",
              width: ["35%", "95%", "100%", "100%", "100%"],
              gap: 2,
              display: ["none", "none", "flex"],
            }}
          >
            {overview.map((i, e) => (
              <>
                {e === 0 ? null : (
                  <Divider
                    orientation="vertical"
                    color="#fff"
                    variant="middle"
                    flexItem
                  />
                )}
                <Typography
                  key={e}
                  component={Link}
                  href={i.path}
                  color="inherit"
                  underline="none"
                  aria-label={i.name}
                  sx={{
                    fontSize: ["1.5rem", ".85rem", "15px", "20px", "1.5rem"],
                    "&:hover": {
                      color: "#F74641",
                    },
                  }}
                >
                  {i.name}
                </Typography>
              </>
            ))}
          </Stack>

          
        <Stack
          direction={"column"}
          sx={{
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography
            color={"inherit"}
            underline="hover"
            sx={{
              fontSize: ["1rem", ".75rem", "12px", "15px", "1.3rem"],
              color: "#f6f6f6",
              textAlign: "center",
            }}
          >
            © {new Date().getFullYear()}  Techbrain Networks. All Rights Reserved.
          </Typography>
        </Stack>
        </Stack>
      </Box>
    </>
  );
}

export default Footer;
