import Box from '@mui/material/Box';

const GradientDivider = () => {
  return (
    <Box
      sx={{
        background:'linear-gradient(to right,transparent,#FF512F,#DD2476 ,transparent)',
        my: 2, // Equivalent to Tailwind's my-8
        height: '2px',
        width: '100%'
      }}
    />
  );
};

export default GradientDivider;
